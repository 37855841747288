import Img from 'gatsby-image'
import React, { useRef } from 'react'

import SimpleHead from 'components/head/SimpleHead'
import PageWrapper from 'components/layout/page-wrapper'
import {
  HeaderSection,
  MobileSection,
  DesktopSection,
  Web3Section,
  AboutSection,
  ChartSection,
  SummarySection,
  SupportedAssetsSection,
} from 'components/pages/asset-page'
import PlatformsSection from 'components/pages/shared/DownloadPlatformsSection'
import defaultContent from 'data/pages/Assets/default'
import buildImageObject from 'js/utils/buildImgObject'
import useFetchSingleAsset from 'js/assets/hooks/useFetchSingleAsset'
import useRellax from 'js/utils/hooks/useRellax'
import useScrollAnimation from 'js/utils/hooks/useScrollAnimation'

import 'static/asset-page/scss/styles.scss'

const hasKey = (obj, key) => Object.prototype.hasOwnProperty.call(obj, key)

const combineData = (src1, src2) => {
  const res = { ...src1 }

  for (const key in src2) {
    if (hasKey(src2, key)) {
      if (typeof src2[key] === 'object' && typeof res[key] === 'object') {
        res[key] = combineData(res[key], src2[key])
      } else if (src2[key] !== undefined && src2[key] !== '') {
        res[key] = src2[key]
      }
    }
  }

  return res
}

const AssetPage = ({ pageContext, location }) => {
  // get page data.
  const { symbol, title, colors, content } = pageContext.data
  const asset = useFetchSingleAsset(symbol)
  const {
    defaultHeader,
    defaultMobile,
    defaultDesktop,
    defaultWeb3,
    defaultAbout,
    defaultSummary,
    defaultAssets,
    defaultDownload,
  } = defaultContent(asset)
  const { header, mobile, desktop, web3, about, summary, assets, download } = content
  const page = asset.ticker.toLowerCase() || 'default'

  const headerData = combineData(defaultHeader, header)
  const mobileData = asset.mobileWallet && combineData(defaultMobile, mobile)
  const desktopData = asset.desktopWallet && combineData(defaultDesktop, desktop)
  const web3Data = asset.web3Wallet && combineData(defaultWeb3, web3)
  const aboutData = combineData(defaultAbout, about)
  const chartData = asset.price.c24h && asset.price.mc && asset.price.v24h
  const summaryData = combineData(defaultSummary, summary)
  const assetsData = combineData(defaultAssets, assets)
  const downloadData = combineData(defaultDownload, download)

  const props = { page, asset, colors }

  // Toggle section active states.
  const sectionRefs = useRef([])
  const ref = (sectionId) => {
    return { ref: (el) => (sectionRefs.current[sectionId] = el) }
  }
  useScrollAnimation({ refs: sectionRefs, disableOnMobile: true })

  useRellax({ className: `${page}-rellax` })

  // Build background image object.
  const background = {
    aspectRatio: 1.778,
    path: `/asset-page/img/${page}/`,
    fileType: 'jpg',
    webp: true,
    files: [
      { fileName: 'header-bg-ssize', width: 640 },
      { fileName: 'header-bg-msize', width: 1080 },
      { fileName: 'header-bg-lsize', width: 1600 },
    ],
  }

  return (
    <PageWrapper className="x__asset-page" headerProps={{ location, trackerPage: page }}>
      <div
        className={`x__asset-page__background-container ${page}-rellax`}
        data-rellax-speed="-4"
        role="presentation"
      >
        <div className="x__asset-page__background">
          <Img fluid={buildImageObject(background)} durationFadeIn={1600} alt={title} />
        </div>
      </div>
      {!!headerData && (
        <HeaderSection {...props} {...ref(0)} data={headerData} location={location} />
      )}
      {!!mobileData && <MobileSection {...props} {...ref(1)} data={mobileData} />}
      {!!desktopData && <DesktopSection {...props} {...ref(2)} data={desktopData} />}
      {!!web3Data && <Web3Section {...props} {...ref(3)} data={web3Data} />}
      {!!aboutData && <AboutSection {...props} {...ref(4)} data={aboutData} />}
      {!!chartData && <ChartSection {...props} {...ref(5)} data={chartData} />}
      {!!summaryData && <SummarySection {...props} {...ref(6)} data={summaryData} />}
      {!!assetsData && <SupportedAssetsSection data={assetsData} {...ref(7)} showCount />}
      {!!downloadData && <PlatformsSection {...props} copy={downloadData.h2} location={location} />}
    </PageWrapper>
  )
}

export default AssetPage

export const Head = ({ pageContext }) => {
  const { title, description, keywords, path } = pageContext.data
  return <SimpleHead title={title} description={description} keywords={keywords} path={path} />
}
