import React from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

// Styles & Images
import 'src/components/assets/shared/scss/AssetIcon.scss'
import emptyIcon from 'src/components/assets/shared/img/empty-asset-icon.svg'
import {
  useColorNetworkIcon,
  useIcon,
  useTransparentNetworkIcon,
} from '../../../js/utils/hooks/useIcons'
import classNames from 'classnames'

// main component.
const AssetIcon = ({
  assetName,
  baseAssetName,
  network,
  name,
  alt = null,
  ticker,
  isCombined,
  size = 'normal',
  showTooltip = false,
  showTransparentNetworkIcon = false,
  showColorNetworkIcon = false,
  style,
  className,
}) => {
  const networkIcon = useTransparentNetworkIcon(baseAssetName)
  const icon = useIcon(assetName)
  const colorNetworkIcon = useColorNetworkIcon(assetName)

  const baseClassName = 'x__asset-icon'
  const iconSrc = (showColorNetworkIcon ? colorNetworkIcon : icon) || emptyIcon
  const iconAltCopy = alt || `${name} (${ticker})${network ? ` ${network} network` : ''}`
  const networkAltCopy = `${network} network`
  const classNameBySize = (str = '') => {
    return `${baseClassName}${str} ${baseClassName}--${size}${str}`
  }

  const element = (
    <i className={classNames(`${baseClassName}__wrapper`, className)} style={style}>
      <img className={classNameBySize()} src={iconSrc} alt={iconAltCopy} />

      {!!networkIcon && !isCombined && showTransparentNetworkIcon && (
        <img className={classNameBySize('__network-icon')} src={networkIcon} alt={networkAltCopy} />
      )}

      {isCombined && (
        <>
          <img className={classNameBySize('--drop1')} src={iconSrc} alt={`${iconAltCopy}--drop1`} />
          <img className={classNameBySize('--drop2')} src={iconSrc} alt={`${iconAltCopy}--drop2`} />
        </>
      )}
    </i>
  )

  if (!showTooltip) return element

  return (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id={`tooltip-${ticker}`}>{iconAltCopy}</Tooltip>}
    >
      {element}
    </OverlayTrigger>
  )
}

export default AssetIcon
