import {
  getAPY,
  getColors,
  getIsInDesktopWallet,
  getIsInMobileWallet,
  getIsInWeb3Wallet,
  getLedger,
  getLink,
  getName,
  getPrice,
  getTicker,
  getTrezor,
} from 'src/js/assets/utils'

const selectAsset = (assetsData, ticker) => {
  for (const key in assetsData) {
    const asset = assetsData[key]
    if (typeof asset === 'object' && asset.displayTicker === ticker) {
      return asset
    }
  }
  return null
}

const processAssetData = (
  dataObjects,
  assetTicker,
  withApy,
  withExchange,
  withPrice,
  withCurrentPrice
) => {
  const asset = selectAsset(dataObjects.assetsData, assetTicker)

  const apy = getAPY(assetTicker, withApy)
  const colors = getColors(asset)
  const desktopWallet = getIsInDesktopWallet(asset)
  const exchange = withExchange?.has(asset?.ticker)
  const link = getLink(assetTicker)
  const mobileWallet = getIsInMobileWallet(asset)
  const name = getName(asset)
  const price = getPrice(assetTicker, withPrice, withCurrentPrice)
  const ticker = getTicker(asset)
  const ledger = getLedger(asset.baseAssetName)
  const trezor = getTrezor(dataObjects.assetsData, asset.name)
  const web3Wallet = getIsInWeb3Wallet(asset)

  return {
    assetName: asset.name,
    baseAssetName: asset.baseAssetName,
    apy,
    colors,
    desktopWallet,
    exchange,
    ledger,
    link,
    mobileWallet,
    name,
    price,
    ticker,
    trezor,
    web3Wallet,
  }
}

const prepareSingleAsset = (
  dataObjects,
  ticker,
  withApy,
  withExchange,
  withPrice,
  withCurrentPrice
) => {
  const asset = processAssetData(
    dataObjects,
    ticker,
    withApy,
    withExchange,
    withPrice,
    withCurrentPrice
  )

  return asset
}

export default prepareSingleAsset
